<template>
	<el-dialog class="dialog" title="添加/编辑权限" v-if="visiable" :visible.sync="visiable" width="30%">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="120px">
				<el-form-item label="名称：" prop="name">
					<el-input v-model="formData.name"></el-input>
				</el-form-item>
				<el-form-item label="排序：" prop="sort">
					<el-input v-model="formData.sort"></el-input>
				</el-form-item>
				<el-form-item label="url：">
					<el-input v-model="formData.url"></el-input>
				</el-form-item>
				<el-form-item label="路径：">
					<el-input v-model="formData.path"></el-input>
				</el-form-item>
				<!-- <el-form-item label="权限编码：">
					<el-input v-model="formData.code"></el-input>
				</el-form-item> -->
				<el-form-item label="类型：">
					<el-select v-model="formData.level" clearable placeholder="请选择">
						<el-option key="一级菜单" label="一级菜单" :value="1">
						</el-option>
						<el-option key="二级菜单" label="二级菜单" :value="2">
						</el-option>
						<el-option key="三级菜单" label="三级菜单" :value="3">
						</el-option>
						<el-option key="四级菜单" label="四级菜单" :value="4">
						</el-option>
						<el-option key="五级菜单" label="五级菜单" :value="5">
						</el-option>
						<el-option key="六级菜单" label="六级菜单" :value="6">
						</el-option>
						<el-option key="七级菜单" label="七级菜单" :value="7">
						</el-option>
						<el-option key="八级菜单" label="八级菜单" :value="8">
						</el-option>
						<el-option key="按钮" label="按钮" :value="9">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="悬停:" prop="hover">
				    <el-radio-group v-model="formData.hover">
				      <el-radio :label="1">可以悬停</el-radio>
				      <el-radio :label="0">不可悬停</el-radio>
				    </el-radio-group>
				  </el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				loading: false,
				formData: {},
				rules: {
					name: [{
						required: true,
						message: '请填写名称',
						trigger: 'change'
					}],
					sort: [{
						required: true,
						message: '请填写排序',
						trigger: 'change'
					}],
					hover: [{
						required: true,
						message: '请选择是否悬停',
						trigger: 'change'
					}],
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.get();
			},
			get() {
				if (this.dialogObj.type == 1) {
					this.$get("/user-api/" + this.dialogObj.role + "/menu/get", {
						'id': this.dialogObj.id
					}).then((res) => {
						if (res.code == 1000) {
							this.formData = res.data;
						} else {
							this.$message.error(res.msg);
						}
					});
				} else if (this.dialogObj.type == 2) {
					this.formData.pid = this.dialogObj.pid;
				}
			},
			//提交表单
			submit() {
				this.loading = true;
				if (this.dialogObj.type == 1) {
					this.$postData("/user-api/" + this.dialogObj.role + "/menu/edit", this.formData).then((res) => {
						if (res.code == 1000) {
							this.loading = false;
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						} else {
							this.loading = false;
							this.$message.error(res.msg);
						}
					});
				} else if (this.dialogObj.type == 2) {
					this.$postData("/user-api/" + this.dialogObj.role + "/menu/add", this.formData).then((res) => {
						if (res.code == 1000) {
							this.loading = false;
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						} else {
							this.loading = false;
							this.$message.error(res.msg);
						}
					});
				}
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}
</style>
