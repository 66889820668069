<template>
	<div class="permission flex" style="flex-direction: column;height: 100%;">
		<el-tabs type="border-card" style="height: 100%;display: flex;flex-direction: column;" @tab-click="tabClick">
			<el-tab-pane label="平台方">
				<div class="tableModule" style="height: 100%;">
					<div class="title">
						<div class="title-right"><el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px;">新增</el-button></div>
					</div>
					<div class="tableContainer">
						<div class="table" ref="tableContainer1">
							<div>
								<el-table
									ref="table1"
									:data="tableData"
									border
									:height="tableHeight"
									:header-cell-style="{ 'background-color': '#f9fafc' }"
									:cell-style="{ padding: 0 }"
									row-key="id"
									:tree-props="{ children: 'child' }"
									@row-click="expandChange(1, arguments)"
									style="margin-bottom: 20px;"
								>
									<el-table-column prop="name" label="架构名称" show-overflow-tooltip></el-table-column>
									<el-table-column prop="sort" label="排序" show-overflow-tooltip sortable></el-table-column>
									<el-table-column prop="level" label="类型" show-overflow-tooltip>
										<template slot-scope="scope">
											<div>{{menuList[scope.row.level]}}</div>
										</template>
									</el-table-column>
									<el-table-column prop="url" label="url" show-overflow-tooltip></el-table-column>
									<el-table-column prop="path" label="路径" show-overflow-tooltip></el-table-column>
									<el-table-column prop="code" label="权限编码" show-overflow-tooltip></el-table-column>
									<el-table-column fixed="right" label="操作" width="460">
										<template slot-scope="scope">
											<el-button type="text" @click="insertSub(scope.row)">添加下级</el-button>
											<el-button type="text" @click="edit(scope.row)">编辑</el-button>
											<el-button type="text" @click="remove(scope.row)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="企业方">
				<div class="tableModule" style="height: 100%;">
					<div class="title">
						<div class="title-right"><el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px;">新增</el-button></div>
					</div>
					<div class="tableContainer">
						<div class="table" ref="tableContainer2">
							<div>
								<el-table
									ref="table2"
									:data="tableData"
									border
									:height="tableHeight"
									:header-cell-style="{ 'background-color': '#f9fafc' }"
									:cell-style="{ padding: 0 }"
									row-key="id"
									:tree-props="{ children: 'child' }"
									@row-click="expandChange(2, arguments)"
									style="margin-bottom: 20px;"
								>
									<el-table-column prop="name" label="架构名称" show-overflow-tooltip></el-table-column>
									<el-table-column prop="sort" label="排序" show-overflow-tooltip sortable></el-table-column>
									<el-table-column prop="level" label="类型" show-overflow-tooltip>
										<template slot-scope="scope">
											<div>{{menuList[scope.row.level]}}</div>
										</template>
									</el-table-column>
									<el-table-column prop="url" label="url" show-overflow-tooltip></el-table-column>
									<el-table-column prop="path" label="路径" show-overflow-tooltip></el-table-column>
									<el-table-column prop="code" label="权限编码" show-overflow-tooltip></el-table-column>
									<el-table-column fixed="right" label="操作" width="460">
										<template slot-scope="scope">
											<el-button type="text" @click="insertSub(scope.row)">添加下级</el-button>
											<el-button type="text" @click="edit(scope.row)">编辑</el-button>
											<el-button type="text" @click="remove(scope.row)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="专家方">
				<div class="tableModule" style="height: 100%;">
					<div class="title">
						<div class="title-right"><el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px;">新增</el-button></div>
					</div>
					<div class="tableContainer">
						<div class="table" ref="tableContainer3">
							<div>
								<el-table
									ref="table3"
									:data="tableData"
									border
									:height="tableHeight"
									:header-cell-style="{ 'background-color': '#f9fafc' }"
									:cell-style="{ padding: 0 }"
									row-key="id"
									:tree-props="{ children: 'child' }"
									@row-click="expandChange(3, arguments)"
									style="margin-bottom: 20px;"
								>
									<el-table-column prop="name" label="架构名称" show-overflow-tooltip></el-table-column>
									<el-table-column prop="sort" label="排序" show-overflow-tooltip sortable></el-table-column>
									<el-table-column prop="level" label="类型" show-overflow-tooltip>
										<template slot-scope="scope">
											<div>{{menuList[scope.row.level]}}</div>
										</template>
									</el-table-column>
									<el-table-column prop="url" label="url" show-overflow-tooltip></el-table-column>
									<el-table-column prop="path" label="路径" show-overflow-tooltip></el-table-column>
									<el-table-column prop="code" label="权限编码" show-overflow-tooltip></el-table-column>
									<el-table-column fixed="right" label="操作" width="460">
										<template slot-scope="scope">
											<el-button type="text" @click="insertSub(scope.row)">添加下级</el-button>
											<el-button type="text" @click="edit(scope.row)">编辑</el-button>
											<el-button type="text" @click="remove(scope.row)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="供应商">
				<div class="tableModule" style="height: 100%;">
					<div class="title">
						<div class="title-right"><el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px;">新增</el-button></div>
					</div>
					<div class="tableContainer">
						<div class="table" ref="tableContainer4">
							<div>
								<el-table
									ref="table4"
									:data="tableData"
									border
									:height="tableHeight"
									:header-cell-style="{ 'background-color': '#f9fafc' }"
									:cell-style="{ padding: 0 }"
									row-key="id"
									:tree-props="{ children: 'child' }"
									@row-click="expandChange(4, arguments)"
									style="margin-bottom: 20px;"
								>
									<el-table-column prop="name" label="架构名称" show-overflow-tooltip></el-table-column>
									<el-table-column prop="sort" label="排序" show-overflow-tooltip sortable></el-table-column>
									<el-table-column prop="level" label="类型" show-overflow-tooltip>
										<template slot-scope="scope">
											<div>{{menuList[scope.row.level]}}</div>
										</template>
									</el-table-column>
									<el-table-column prop="url" label="url" show-overflow-tooltip></el-table-column>
									<el-table-column prop="path" label="路径" show-overflow-tooltip></el-table-column>
									<el-table-column prop="code" label="权限编码" show-overflow-tooltip></el-table-column>
									<el-table-column fixed="right" label="操作" width="460">
										<template slot-scope="scope">
											<el-button type="text" @click="insertSub(scope.row)">添加下级</el-button>
											<el-button type="text" @click="edit(scope.row)">编辑</el-button>
											<el-button type="text" @click="remove(scope.row)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>

		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
	</div>
</template>
<script>
import smCard from '@c/smCard.vue';
import edit from './edit.vue';
export default {
	components: {
		smCard,
		edit
	},
	data() {
		return {
			tableHeight: 0,
			tableData: [],
			searchParm: {
				size: 10,
				current: 1
			},
			showEditDialog: false,
			editObj: {},
			role: '',
			menuList: ['', '一级菜单', '二级菜单', '三级菜单', '四级菜单', '五级菜单', '六级菜单', '七级菜单', '八级菜单', '按钮']
		};
	},
	methods: {
		//点击某一行的回调事件
		expandChange(i, obj) {
			let row = obj[0];
			let index = obj[1];
			let e = obj[2];
			if (index.label == '架构名称') {
				this.$refs['table' + i].toggleRowExpansion(row);
			}
		},
		//自适应表格高度
		calHeight() {
			this.$nextTick(() => {
				for (let i = 1; i <= 4; i++) {
					let rect = this.$refs['tableContainer' + i].getBoundingClientRect();
					if (rect.height != 0) {
						this.tableHeight = rect.height;
						break;
					}
				}
			});
		},
		//点击tab回调
		tabClick(tab) {
			if (tab.label == '平台方') {
				this.role = 'plat';
			} else if (tab.label == '企业方') {
				this.role = 'ent';
			}
			if (tab.label == '专家方') {
				this.role = 'exp';
			}
			if (tab.label == '供应商') {
				this.role = 'qs';
			}
			this.list();
		},
		//展示数据
		list() {
			this.$get('/user-api/' + this.role + '/menu/tree', null).then(res => {
				if (res.code == 1000) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//打开添加弹窗
		insert() {
			this.editObj = {};
			this.editObj['type'] = 2;
			this.editObj['role'] = this.role;
			this.editObj['pid'] = 0;
			this.showEditDialog = true;
		},
		//打开添加下级弹窗
		insertSub(row) {
			this.editObj = {};
			this.editObj['type'] = 2;
			this.editObj['role'] = this.role;
			this.editObj['pid'] = row.id;
			this.editObj['code'] = row.code;
			this.showEditDialog = true;
		},
		//打开编辑弹窗
		edit(row) {
			this.editObj = {};
			this.editObj['type'] = 1;
			this.editObj['role'] = this.role;
			this.editObj['id'] = row.id;
			this.editObj['code'] = row.code;
			this.showEditDialog = true;
		},
		remove(row) {
			this.$confirm('是否确认删除？', '删除权限', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$post('/user-api/' + this.role + '/menu/delete', {
						id: row.id
					}).then(res => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(() => {});
		}
	},
	mounted() {
		this.role = 'plat';
		this.list();
		this.calHeight();
		window.addEventListener('resize', this.calHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';

.permission {
	::v-deep.el-tabs__content {
		flex: 1;
	}

	::v-deep.el-tab-pane {
		height: 100%;
	}
}
</style>
